import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function ElizaJane() {
  return (
    <Layout>
      <Helmet title="Eliza Jane | David Ramirez" />

      <h1>Eliza Jane</h1>

      <h2>David Ramirez</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Get out of Oklahoma</p>
          <p>Run away from all that dust</p>
          <p>Pack up your family in that Cadillac you love so much</p>
          <p>I hear Oregon is nice</p>
          <p>Maybe you can find some work</p>
          <p>Set up a canteen selling coffee by the bus stop</p>
        </Verse>
        <Chorus>
          <p>Oh but keep playing that piano</p>
          <p>Who knows, maybe you can make it on the radio</p>
          <p>Did you ever know your hands can do more than work the land</p>
          <p>Well I heard your song and I can't get it out of my head</p>
        </Chorus>
        <Verse>
          <p>Were you in it for the spotlight</p>
          <p>Or was it something that you needed to do</p>
          <p>Did you love it for the nightlife</p>
          <p>Staying out late for a drink or two</p>
          <p>Oh can you tell me where I came from</p>
          <p>Can you show me what it all means</p>
          <p>To put the pen on a page, standing on a stage</p>
          <p>Hoping that somebody believes</p>
        </Verse>
        <Chorus>
          <p>Oh but keep playing that piano</p>
          <p>Who knows, maybe you can make it on the radio</p>
          <p>Did you ever know your hands can do more than work the land</p>
          <p>Well I heard your song and I can't get it out of my head</p>
        </Chorus>
        <Bridge>
          <p>Your still singing</p>
          <p>Singing your song</p>
          <p>Your still giving</p>
          <p>Even when your gone</p>
          <p>Your still singing</p>
          <p>Singing your song</p>
          <p>Your still giving</p>
          <p>Even when your gone</p>
        </Bridge>
        <Chorus>
          <p>Oh but keep playing that piano</p>
          <p>Who knows, maybe you can make it on the radio</p>
          <p>Did you ever know your hands can do more than work the land</p>
          <p>Well I heard your song and I can't get it out of my head</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
